<template>
  <b-sidebar
    id="add-new-receiver-sidebar"
    :visible="isAddNewReceiverSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @shown="showSidebar"
    @change="(val) => $emit('update:is-add-new-receiver-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('AppReceiver.newReceiver') }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Customer-->
          <validation-provider
            #default="{ errors }"
            :name="$t('AppReceiver.columns.customer')"
            rules="required"
          >
            <b-form-group
              :label="$t('AppReceiver.columns.customer')"
              :state="errors.length > 0 ? false:null"
            >
              <v-select
                v-model="receiverData.clienteId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                label="texto"
                :options="customers"
                :reduce="option => option.id"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Alias-->
          <validation-provider
            #default="validationContext"
            :name="$t('AppReceiver.columns.nickname')"
            rules="required|max:10"
          >
            <b-form-group
              :label="$t('AppReceiver.columns.nickname')"
              label-for="alias"
            >
              <b-form-input
                id="alias"
                v-model="receiverData.alias"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppReceiver.columns.name')"
            rules="required|max:150"
          >
            <b-form-group
              :label="$t('AppReceiver.columns.name')"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                v-model="receiverData.nombre"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- RFC -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppReceiver.columns.rfc')"
            rules="required|max:13|min:12"
          >
            <b-form-group
              :label="$t('AppReceiver.columns.rfc')"
              label-for="rfc"
            >
              <b-form-input
                id="rfc"
                v-model="receiverData.rfc"
                :state="getValidationState(validationContext)"
                trim
                onkeyup="this.value = this.value.toUpperCase();"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Domicilio -->
          <validation-provider
            #default="validationContext"
            :name="$t('AppReceiver.columns.address')"
            rules="required|max:250"
          >
            <b-form-group
              :label="$t('AppReceiver.columns.address')"
              label-for="domicilio"
            >
              <b-form-input
                id="domicilio"
                v-model="receiverData.domicilio"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, alphaNum, email, min, max,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import optionService from '@/services/option.service'
import receiverService from '@/services/receiver.service'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewReceiverSidebarActive',
    event: 'update:is-add-new-Receiver-sidebar-active',
  },
  props: {
    isAddNewReceiverSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      max,
    }
  },
  setup(props, { emit }) {
    /* Services */
    const {
      createReceiver,
    } = receiverService()
    const blankReceiverData = {
      clienteId: null,
      alias: '',
      nombre: '',
      rfc: '',
      domicilio: '',
    }

    const receiverData = ref(JSON.parse(JSON.stringify(blankReceiverData)))

    const resetreceiverData = () => {
      receiverData.value = JSON.parse(JSON.stringify(blankReceiverData))
    }

    const onSubmit = () => {
      createReceiver(receiverData.value)
        .then(() => {
          emit('sort-receiver-id-asc')
          emit('refetch-data')
          emit('update:is-add-new-receiver-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetreceiverData)

    const customers = ref([])
    const { fetchCustomers } = optionService()
    fetchCustomers(data => { customers.value = data })

    const showSidebar = () => {
      if (customers.value.length === 1) {
        receiverData.value.clienteId = customers.value[0].id
      }
    }

    return {
      customers,
      receiverData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      showSidebar,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-receiver-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
