<template>
  <div>
    <receiver-list-add-new
      :is-add-new-receiver-sidebar-active.sync="isAddNewReceiverSidebarActive"
      @refetch-data="refetchData"
      @sort-receiver-id-asc="sortReceiverIdAsc"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Lists.Search')"
              />
              <b-button
                variant="primary"
                @click="isAddNewReceiverSidebarActive = true"
              >
                <span class="text-nowrap">{{ $t('AppReceiver.newReceiver') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refReceiverListTable"
        class="position-relative"
        :items="fetchCusomers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('Lists.NoMatchingRecordsFound')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-receivers-edit', params: { destinatarioId: data.item.destinatarioId } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
            </b-dropdown-item>
            <b-dropdown-item @click="showDeleteConfirmation(data.item.destinatarioId)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Delete') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.from }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalReceivers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import i18n from '@/libs/i18n'
import receiverService from '@/services/receiver.service'
import useReceiversList from './useReceiversList'
import receiverStoreModule from '../receiverStoreModule'
import ReceiverListAddNew from './ReceiverListAddNew.vue'

export default {
  components: {
    ReceiverListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* Services */
    const {
      deleteReceiver,
    } = receiverService()
    const CUSTOMER_APP_STORE_MODULE_NAME = 'app-receiver'

    // Register module
    if (!store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_APP_STORE_MODULE_NAME, receiverStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_APP_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_APP_STORE_MODULE_NAME)
    })

    const isAddNewReceiverSidebarActive = ref(false)

    const {
      fetchCusomers,
      tableColumns,
      perPage,
      currentPage,
      totalReceivers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReceiverListTable,
      refetchData,
      sortReceiverIdAsc,
    } = useReceiversList()

    return {
      // Services
      deleteReceiver,

      // Sidebar
      isAddNewReceiverSidebarActive,

      fetchCusomers,
      tableColumns,
      perPage,
      currentPage,
      totalReceivers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refReceiverListTable,
      refetchData,
      sortReceiverIdAsc,
    }
  },
  methods: {
    showDeleteConfirmation(destinatarioId) {
      this.$bvModal
        .msgBoxConfirm(i18n.t('AppReceiver.questionDeleteReceiver'), {
          title: i18n.t('Lists.pleaseConfirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: i18n.t('Lists.Yes'),
          cancelTitle: i18n.t('Lists.No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(deleteConfirmation => {
          if (deleteConfirmation) {
            this.deleteReceiver({ destinatarioId })
              .then(() => {
                this.refetchData()
              })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
