import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import i18n from '@/libs/i18n'

export default function useReceiversList() {
  // Use toast
  const toast = useToast()

  const refReceiverListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'destinatarioId',
      label: i18n.t('AppReceiver.columns.receiverId'),
      sortable: true,
      sortKey: 'DestinatarioId',
    },
    {
      key: 'clienteNombre',
      label: i18n.t('AppReceiver.columns.customer'),
      sortable: true,
      sortKey: 'ClienteNombre',
    },
    {
      key: 'alias',
      label: i18n.t('AppReceiver.columns.nickname'),
      sortable: true,
      sortKey: 'Alias',
    },
    {
      key: 'nombre',
      label: i18n.t('AppReceiver.columns.name'),
      sortable: true,
      sortKey: 'Nombre',
    },
    {
      key: 'rfc',
      label: i18n.t('AppReceiver.columns.rfc'),
      sortable: true,
      sortKey: 'Rfc',
    },
    {
      key: 'domicilio',
      label: i18n.t('AppReceiver.columns.address'),
      sortable: true,
      sortkey: 'Domicilio',
    },
    {
      label: i18n.t('Lists.Actions'),
      key: 'actions',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalReceivers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('destinatarioId')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refReceiverListTable.value ? refReceiverListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReceivers.value,
    }
  })

  const refetchData = () => {
    refReceiverListTable.value.refresh()
  }

  const sortReceiverIdAsc = () => {
    isSortDirDesc.value = false
    sortBy.value = 'destinatarioId'
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchCusomers = (ctx, callback) => {
    store
      .dispatch('app-receivers/fetchReceivers', {
        searchQuery: searchQuery.value,
        // alias: searchQuery.value,
        // nombre: searchQuery.value,
        // rfc: searchQuery.value,
        // domicilio: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      })
      .then(response => {
        const { destinatarios, total } = response.data
        callback(destinatarios)
        totalReceivers.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('AppReceiver.fetchError'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  return {
    fetchCusomers,
    tableColumns,
    perPage,
    currentPage,
    totalReceivers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReceiverListTable,
    refetchData,
    sortReceiverIdAsc,
  }
}
